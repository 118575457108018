const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel', 'Engine'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands.php';

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  facets: {
    simpleFacet: { fields: ['review'], name: 'reviewFacet' },
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page-type-category .page, body.page-type-brand .page',
      template: 'SearchPage',
      visibleIf: { ignoreUrls: [categorySelectionPageUrl, brandSelectionPageUrl] },
    },
    {
      name: 'CategoryPageTiles',
      type: 'FacetTiles',
      location: {
        insertBefore: 'body.page-type-category .page-heading',
      },
      template: 'facetTiles/categoryPageTiles',
      visibleIf: { ignoreUrls: [categorySelectionPageUrl, brandSelectionPageUrl] },
      excludeFieldPreselection: 'category',
      facetField: 'category',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: '#quick_search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        firstChildOf: '#Navcontainer .MobileFlexDisplay',
        class: 'cm_search-box-root__dialog-container',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: 'body.page-type-default .HPLeft .YMM',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        wrapper: 'li',
        insertAfter: '#Navcontainer .MobileFlexDisplay',
        id: 'cm_desktop-garage',
      },
      template: 'fitmentSearch/garage',
      visibleIf: () => window.innerWidth >= 748,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        wrapper: 'li',
        insertAfter: '#Navcontainer .MobileFlexDisplay a[href="/account.php"]',
        class: 'cm_garage__mobile',
        id: 'cm_mobile-garage',
      },
      template: 'fitmentSearch/garage',
      visibleIf: () => window.innerWidth <= 747,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#product-fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.page-type-brands .page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        replace: 'body.page-type-category .page',
      },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
