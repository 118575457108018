//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-842:_7144,_5224,_7136,_128,_6704,_3800,_72,_3232;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-842')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-842', "_7144,_5224,_7136,_128,_6704,_3800,_72,_3232");
        }
      }catch (ex) {
        console.error(ex);
      }