//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-842:_2256,_8300,_512,_3998,_4190,_2976,_7760,_6872;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-842')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-842', "_2256,_8300,_512,_3998,_4190,_2976,_7760,_6872");
        }
      }catch (ex) {
        console.error(ex);
      }